import { useMutation } from '@apollo/client';
import { head, isEmpty, isNumber } from 'lodash';
import { SyntheticEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  getFeatureEnabledStatus,
  getImageCollectionStatus,
  getLogCollectionStatus,
  getNotificationStatus,
  getRetentionDays
} from './contextualize-cloud-settings-status';
import { DeviceNotes } from './device-notes';
import { GetDeviceDetailsQuery, OperationalLifeCycle } from '../../../../__generated__/graphql';
import ArrowLeftIcon from '../../../../assets/icons/arrow-left.svg?react';
import LocationIcon from '../../../../assets/icons/location.svg?react';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { MUTATION_COLLECT_DEVICE_SOFTWARE_VERSION } from '../../../../services/mutations';
import { DeviceOperationalLifeCycleRemarksDrawerProps } from '../../../../types';
import {
  generateBalenaLink,
  generateGoogleMapsUrl,
  generateJiraLink,
  getNonNullishDisplayValue,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities';
import { CustomerSiteProgramSubtitle, EditButton, SiteImpression } from '../../../4-features';
import { ExternalLink, InformationBlock, InternalLink, RSAccordion, RSActionLink } from '../../../5-elements';
import { UserCompanyTypeContext, UserTimezoneContext } from '../../../contexts';
import { useEnqueueSnackbar } from '../../../hooks';
import { DeviceCloudSettingsEdit } from '../device-cloud-settings-edit';
import {
  mapAutoImageCollectionValueToRadioOption,
  mapAutoLogCollectionValuesToRadioOption,
  mapNotificationValuesToRadioOption
} from '../device-cloud-settings-edit/validation-schema';
import { DeviceDetailsEdit } from '../device-details-edit';
import { DeviceOperationalLifeCycleRemarks } from '../device-operational-life-cycle-remarks';

interface DeviceDetailsSidePanelProps {
  device: NonNullable<GetDeviceDetailsQuery['deviceByPK']>;
  canUserEditDevice?: boolean;
}

export const DeviceDetailsSidePanel = ({ device, canUserEditDevice }: DeviceDetailsSidePanelProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>('detailed-information-details-accordion');
  const [openDeviceOperationalLifeCycleRemarks, setOpenDeviceOperationalLifeCycleRemarks] =
    useState<DeviceOperationalLifeCycleRemarksDrawerProps>({ isOpen: false });
  const [openDeviceDetailsEdit, setOpenDeviceDetailsEdit] = useState<boolean>(false);
  const [openDeviceCloudSettingsEdit, setOpenDeviceCloudSettingsEdit] = useState<boolean>(false);
  const {
    showServicePortal,
    error: userCompanyContextError,
    loading: loadingUserCompanyContext
  } = useContext(UserCompanyTypeContext);
  const [collectDeviceSoftwareVersion, { loading: loadingCollectDeviceSoftwareVersion }] = useMutation(
    MUTATION_COLLECT_DEVICE_SOFTWARE_VERSION,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.detailedInformation.software.snackbarMessages.reCheckSoftwareVersionError'),
          undefined,
          error.message || error.name,
          'error'
        );
      },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.detailedInformation.software.snackbarMessages.reCheckSoftwareVersionOK'),
          undefined,
          undefined,
          'success'
        );
      }
    }
  );

  const handleCollectDeviceSoftwareVersion = (): void => {
    collectDeviceSoftwareVersion({ variables: { deviceIds: [device.id] } });
  };

  if (loadingUserCompanyContext || userCompanyContextError) {
    return <></>;
  }

  const handleAccordionExpansion =
    (accordion: string) =>
    (_event: SyntheticEvent, isExpanded: boolean): void => {
      setExpandedAccordion(isExpanded ? accordion : false);
    };

  // Use for an information block to span over two grid columns
  const oneColumnInformationBlockClassNames = [
    'device-details-side-panel__information-block',
    'device-details-side-panel__information-block--two-columns'
  ];

  const deviceImageId = isEmpty(device.deviceImages) ? undefined : head(device.deviceImages)!.id;

  return (
    <section className="device-details-side-panel" data-testid="device-details-side-panel">
      <div className="device-details-side-panel__main-information">
        <InternalLink
          to={`${appConfig.basePath}/devices`}
          icon={<ArrowLeftIcon />}
          text={t('deviceDetailsPage.sidePanel.backLinkText')}
        />
        <div
          className="device-details-side-panel__main-information-title"
          data-testid="device-details-side-panel-title"
        >
          <h1>{getNonNullishDisplayValue(device.serialNumber)}</h1>
          <CustomerSiteProgramSubtitle
            customerName={device.site.customer.company.name}
            siteName={device.site.name}
            programNumber={getNonNullishDisplayValue(device.program?.name)}
            customerId={device.site.customer.companyId}
          />
        </div>
        <SiteImpression deviceId={device.id} imageId={deviceImageId} canUserEditDevice={canUserEditDevice} />
        <div className="device-details-side-panel__operational-life-cycle">
          <InformationBlock
            label={t('deviceDetailsPage.sidePanel.mainInformation.operationalLifeCycle')}
            value={getNonNullishDisplayValue(mapOperationalLifeCycleDisplayLabel(device.operationalLifeCycle))}
            data-testid="information-block-operational-life-cycle"
          />
          {canUserEditDevice && (
            <div className="device-details-side-panel__operational-life-cycle-edit">
              <EditButton
                onClick={() =>
                  setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'edit', startMode: 'edit' })
                }
                data-testid="operational-life-cycle-remarks-edit-button"
              />
            </div>
          )}
        </div>
        <InformationBlock
          label={t('deviceDetailsPage.sidePanel.mainInformation.notes')}
          value={
            <DeviceNotes
              actionLinkText={t('longText.showMore')}
              remarks={getNonNullishDisplayValue(device.remarks, true)}
              handleActionLinkClick={() =>
                setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'view', startMode: 'view' })
              }
            />
          }
          data-testid="information-block-device-notes"
        />
        <div className="device-details-side-panel__detailed-information">
          <RSAccordion
            expanded={expandedAccordion === 'detailed-information-details-accordion'}
            onChange={handleAccordionExpansion('detailed-information-details-accordion')}
            accordionSummaryProps={{
              children: (
                <div className="device-details-side-panel__details-title">
                  <h3>{t('deviceDetailsPage.sidePanel.detailedInformation.details.title')}</h3>
                  {canUserEditDevice && (
                    <EditButton
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenDeviceDetailsEdit(true);
                      }}
                    />
                  )}
                </div>
              )
            }}
            accordionDetailsProps={{
              children: (
                <div className="device-details-side-panel__detailed-information-details">
                  <InformationBlock
                    extraClassNames={oneColumnInformationBlockClassNames}
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.rocType')}
                    value={device.deviceType.name}
                  />
                  <InformationBlock
                    extraClassNames={oneColumnInformationBlockClassNames}
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.description')}
                    value={getNonNullishDisplayValue(device.description, true)}
                  />
                  <InformationBlock
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.connectorType')}
                    value={getNonNullishDisplayValue(device.connectorHolderType?.connectorType)}
                  />
                  <InformationBlock
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.connecterHolderType')}
                    value={getNonNullishDisplayValue(device.connectorHolderType?.name)}
                  />
                  <InformationBlock
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.location')}
                    data-testid="device-details-side-panel-location"
                    value={
                      device.location?.latitude && device.location?.longitude ? (
                        <ExternalLink
                          icon={<LocationIcon />}
                          url={generateGoogleMapsUrl(device.location.latitude, device.location.longitude)}
                        >
                          {t('deviceDetailsPage.sidePanel.detailedInformation.details.showOnMap')}
                        </ExternalLink>
                      ) : (
                        t('noData')
                      )
                    }
                  />
                  <InformationBlock
                    label={t('deviceDetailsPage.sidePanel.detailedInformation.details.timezone')}
                    value={
                      <span className="device-details-side-panel__details-timezone">
                        {device.site.timezone.name}&nbsp;
                        <RSActionLink
                          data-testid="device-details-set-timezone"
                          onClick={() => {
                            if (userTimezone !== device.site.timezone.name) {
                              searchParams.set('timezone', device.site.timezone.name);
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          {t('deviceDetailsPage.sidePanel.detailedInformation.details.setTimezone')}
                        </RSActionLink>
                      </span>
                    }
                  />
                </div>
              )
            }}
          />
          {device.deviceSoftwareConfigurationActive !== null && (
            <RSAccordion
              expanded={expandedAccordion === 'detailed-information-software-accordion'}
              onChange={handleAccordionExpansion('detailed-information-software-accordion')}
              accordionSummaryProps={{
                children: (
                  <h3 data-testid="device-details-side-panel-software-accordion">
                    {t('deviceDetailsPage.sidePanel.detailedInformation.software.title')}
                  </h3>
                )
              }}
              accordionDetailsProps={{
                children: (
                  <div className="device-details-side-panel__detailed-information-software">
                    <InformationBlock
                      extraClassNames={oneColumnInformationBlockClassNames}
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.software.rocOs')}
                      value={getNonNullishDisplayValue(device.deviceSoftwareConfigurationActive?.rocosComposedVersion)}
                    />
                    {showServicePortal && (
                      <>
                        <InformationBlock
                          label={t('deviceDetailsPage.sidePanel.detailedInformation.software.balenaOsVersion')}
                          value={getNonNullishDisplayValue(device.deviceSoftwareConfigurationActive?.balenaOsVersion)}
                        />
                        <InformationBlock
                          label={t('deviceDetailsPage.sidePanel.detailedInformation.software.balenaOsVariant')}
                          value={getNonNullishDisplayValue(device.deviceSoftwareConfigurationActive?.balenaOsVariant)}
                        />
                        <InformationBlock
                          label={t('deviceDetailsPage.sidePanel.detailedInformation.software.balenaReleaseCommit')}
                          value={getNonNullishDisplayValue(
                            device.deviceSoftwareConfigurationActive?.balenaReleaseCommit
                          )}
                          valueProps={{
                            title: getNonNullishDisplayValue(
                              device.deviceSoftwareConfigurationActive?.balenaReleaseCommit
                            )
                          }}
                        />
                        <InformationBlock
                          label={t('deviceDetailsPage.sidePanel.detailedInformation.software.balenaSupervisorVersion')}
                          value={getNonNullishDisplayValue(
                            device.deviceSoftwareConfigurationActive?.balenaSupervisorVersion
                          )}
                        />
                      </>
                    )}
                    {canUserEditDevice && (
                      <InformationBlock
                        extraClassNames={oneColumnInformationBlockClassNames}
                        label=""
                        value={
                          <span className="device-details-side-panel__detailed-information-software__recheck-connectivity">
                            <RSActionLink
                              disabled={loadingCollectDeviceSoftwareVersion}
                              data-testid="device-details-side-panel-software-version-recheck"
                              onClick={() => handleCollectDeviceSoftwareVersion()}
                            >
                              {t('deviceDetailsPage.sidePanel.detailedInformation.software.reCheckSoftwareVersion')}
                            </RSActionLink>
                          </span>
                        }
                      />
                    )}
                  </div>
                )
              }}
            />
          )}
          {device.deviceCloudSettings !== null && (
            <RSAccordion
              expanded={expandedAccordion === 'detailed-information-cloud-settings-accordion'}
              onChange={handleAccordionExpansion('detailed-information-cloud-settings-accordion')}
              accordionSummaryProps={{
                children: (
                  <div
                    className="device-details-side-panel__cloud-settings-title"
                    data-testid="device-details-side-panel-cloud-settings-accordion"
                  >
                    <h3>{t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.title')}</h3>
                    {canUserEditDevice && (
                      <EditButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenDeviceCloudSettingsEdit(true);
                        }}
                      />
                    )}
                  </div>
                )
              }}
              accordionDetailsProps={{
                children: (
                  <div className="device-details-side-panel__detailed-information-cloud-settings">
                    <InformationBlock
                      extraClassNames={oneColumnInformationBlockClassNames}
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.notifications')}
                      value={getNotificationStatus(
                        device.deviceCloudSettings?.notificationsEnabled,
                        device.deviceCloudSettings?.notificationsOnFailedCycleEnabled
                      )}
                    />
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.automaticLogCollection')}
                      value={getLogCollectionStatus(
                        device.deviceCloudSettings?.autoLogCollectionEnabled,
                        device.deviceCloudSettings?.autoLogCollectionOnSuccessEnabled
                      )}
                    />
                    <InformationBlock
                      label={t(
                        'deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.automaticImageCollection'
                      )}
                      value={getImageCollectionStatus(device.deviceCloudSettings?.autoImageCollectionEnabled)}
                    />
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.rocysApi')}
                      value={getFeatureEnabledStatus(device.deviceCloudSettings?.rocsysApiEnabled)}
                    />
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.vpn')}
                      value={getFeatureEnabledStatus(device.deviceCloudSettings?.vpnEnabled)}
                    />
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.rententionAcdCycles')}
                      value={getRetentionDays(device.deviceCloudSettings?.deviceOperationsRetentionDays)}
                    />
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.cloudSettings.retentionMeasurements')}
                      value={getRetentionDays(device.deviceCloudSettings?.deviceMeasurementsRetentionDays)}
                    />
                  </div>
                )
              }}
            />
          )}
          <RSAccordion
            expanded={expandedAccordion === 'detailed-information-external-link-accordion'}
            onChange={handleAccordionExpansion('detailed-information-external-link-accordion')}
            accordionSummaryProps={{
              children: <h3>{t('deviceDetailsPage.sidePanel.detailedInformation.externalLinks.title')}</h3>
            }}
            accordionDetailsProps={{
              children: (
                <div
                  data-testid="device-details-side-panel-external-links"
                  className="device-details-side-panel__detailed-information-external-link"
                >
                  {device.serialNumber && (
                    <InformationBlock
                      label={t(
                        `deviceDetailsPage.sidePanel.detailedInformation.externalLinks.jira${showServicePortal ? '' : 'CustomerPortal'}`
                      )}
                      data-testid="device-details-side-panel-jira-link"
                      extraClassNames={!showServicePortal ? oneColumnInformationBlockClassNames : []}
                      value={
                        <ExternalLink url={generateJiraLink(device.serialNumber, showServicePortal)}>
                          <p>
                            {t(
                              `deviceDetailsPage.sidePanel.detailedInformation.externalLinks.jiraLinkCaption${showServicePortal ? '' : 'CustomerPortal'}`
                            )}
                          </p>
                        </ExternalLink>
                      }
                    />
                  )}
                  {device.balenaUid && showServicePortal && (
                    <InformationBlock
                      label={t('deviceDetailsPage.sidePanel.detailedInformation.externalLinks.balena')}
                      data-testid="device-details-side-panel-balena-link"
                      value={
                        <ExternalLink url={generateBalenaLink(device.balenaUid)}>
                          <p>{t('deviceDetailsPage.sidePanel.detailedInformation.externalLinks.balenaLinkCaption')}</p>
                        </ExternalLink>
                      }
                    />
                  )}
                </div>
              )
            }}
          />
        </div>
        <DeviceOperationalLifeCycleRemarks
          open={openDeviceOperationalLifeCycleRemarks.isOpen}
          defaultValues={{
            id: device.id,
            operationalLifeCycle: device.operationalLifeCycle as OperationalLifeCycle,
            remarks: device.remarks
          }}
          setOpenDeviceOperationalLifeCycleRemarks={setOpenDeviceOperationalLifeCycleRemarks}
          mode={openDeviceOperationalLifeCycleRemarks.mode}
          startMode={openDeviceOperationalLifeCycleRemarks.startMode}
          serialNumber={getNonNullishDisplayValue(device.serialNumber)}
          canUserEditDevice={canUserEditDevice}
        />
        <DeviceDetailsEdit
          open={openDeviceDetailsEdit}
          setOpenDeviceDetailsEdit={setOpenDeviceDetailsEdit}
          defaultValues={{
            id: device.id,
            deviceTypeId: device.deviceType.id,
            connectorHolderTypeId: device.connectorHolderType?.id,
            location:
              isNumber(device.location?.latitude) && isNumber(device.location.longitude)
                ? {
                    latitude: device.location?.latitude,
                    longitude: device.location?.longitude
                  }
                : null,
            description: device.description
          }}
          siteTimezone={device.site.timezone.name}
        />
        <DeviceCloudSettingsEdit
          open={openDeviceCloudSettingsEdit}
          setOpenDeviceCloudSettingsEdit={setOpenDeviceCloudSettingsEdit}
          defaultValues={{
            deviceId: device.id,
            autoLogCollection: mapAutoLogCollectionValuesToRadioOption(
              device.deviceCloudSettings?.autoLogCollectionEnabled,
              device.deviceCloudSettings?.autoLogCollectionOnSuccessEnabled
            ),
            autoImageCollection: mapAutoImageCollectionValueToRadioOption(
              device.deviceCloudSettings?.autoImageCollectionEnabled
            ),
            deviceOperationsRetentionEnabled: Boolean(device.deviceCloudSettings?.deviceOperationsRetentionDays),
            deviceOperationsRetentionDays: device.deviceCloudSettings?.deviceOperationsRetentionDays,
            deviceMeasurementsRetentionEnabled: Boolean(device.deviceCloudSettings?.deviceMeasurementsRetentionDays),
            deviceMeasurementsRetentionDays: device.deviceCloudSettings?.deviceMeasurementsRetentionDays,
            rocsysApiEnabled: Boolean(device.deviceCloudSettings?.rocsysApiEnabled),
            vpnEnabled: Boolean(device.deviceCloudSettings?.vpnEnabled),
            notificationsEnabled: mapNotificationValuesToRadioOption(
              device.deviceCloudSettings?.notificationsEnabled,
              device.deviceCloudSettings?.notificationsOnFailedCycleEnabled
            )
          }}
        />
      </div>
    </section>
  );
};
