import { useAuth0 } from '@auth0/auth0-react';
import { includes } from 'lodash';
import { JSX } from 'react';
import { useLocation } from 'react-router-dom';

import RocsysLogo from '../../../assets/logos/rocsys-logo.svg?react';
import {
  AutoRefreshSwitch,
  RSLinkTimezone,
  ReloadData,
  SupportButton,
  TimezoneSelect,
  UserMenu
} from '../../4-features';

export const PortalHeader = (): JSX.Element => {
  const { user } = useAuth0();
  const routerLocation = useLocation();
  const isAdminPage = includes(routerLocation.pathname, 'portal/admin');

  return (
    <header className="portal-header" data-testid="portal-header">
      <RSLinkTimezone to="/portal" data-testid="portal-header-logo-link">
        <div className="portal-header__logo">
          <RocsysLogo className="portal-header__logo-rocsys" />
        </div>
      </RSLinkTimezone>
      <div className="portal-header__actions">
        {user && <ReloadData />}
        {user && !isAdminPage && <AutoRefreshSwitch />}
        {user && <TimezoneSelect />}
        <UserMenu userName={user?.name} email={user?.email} imageUrl={user?.picture} />
        <SupportButton />
      </div>
    </header>
  );
};
