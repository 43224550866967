import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import { JSX, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ApplicationRoutes } from './application-routes';
import { ContextWrapper } from './contexts';
import { ApolloClientProvider, Auth0ProviderWithNavigate, snackbarProviderConfiguration } from './providers';
import { appConfig } from '../configs';
import '../scss/main.scss';

const PortalApp = (): JSX.Element => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('homepage.title');
  }, []);
  LicenseInfo.setLicenseKey(appConfig.muiLicenseKey);

  // The order of the providers:
  // 1. Auth0ProviderWithNavigate must be under BrowserRouter so that the log-in callback from Auth0 can be set up
  // 2. ApolloClientProvider and PersistGate must be under Auth0ProviderWithNavigate because they both need the bearer
  //    token from Auth0
  return (
    <div className="portal">
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <SnackbarProvider {...snackbarProviderConfiguration}>
            <ApolloClientProvider>
              <ContextWrapper>
                <ApplicationRoutes />
              </ContextWrapper>
            </ApolloClientProvider>
          </SnackbarProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </div>
  );
};

export default PortalApp;
