import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { filter, head, includes, isEmpty, omit, uniq } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddUserRequest, addUserSchema } from './validation-schema';
import {
  CreateUserMutationVariables,
  GetAdminUsersQuery,
  GetCompanyNamesQuery,
  GetUserNamesQuery,
  UserPermissionType,
  UserType
} from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants';
import { MUTATION_CREATE_USER } from '../../../../services/mutations';
import {
  QUERY_GET_COMPANY_NAMES,
  QUERY_GET_USER_AUTH_INFO,
  QUERY_GET_USER_PERMISSION_RIGHTS
} from '../../../../services/queries';
import { CompanyType } from '../../../../types';
import { getAllowedUserPermissions, mapCompanyType, mapUserType } from '../../../../utilities';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import {
  Loading,
  RSCheckbox,
  RSCheckboxGroup,
  RSSelect,
  RSSelectItemProps,
  RSSwitch,
  RSTextInput
} from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';
import { ErrorPage } from '../../error-page';

interface AddUserDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenAddUser: Dispatch<SetStateAction<boolean>>;
  refetchUsers: () => Promise<ApolloQueryResult<GetAdminUsersQuery>>;
  refetchUserNames: () => Promise<ApolloQueryResult<GetUserNamesQuery>>;
  refetchCompanyNames: () => Promise<ApolloQueryResult<GetCompanyNamesQuery>>;
}

export const AddUserDrawer = ({
  setOpenAddUser,
  refetchUsers,
  refetchUserNames,
  refetchCompanyNames,
  ...props
}: AddUserDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { user } = useAuth0();
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: props.open === false || !user?.sub
  });
  const {
    loading: loadingCompanyNames,
    data: dataCompanyNames,
    error: errorCompanyNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, { fetchPolicy: 'network-only', skip: props.open === false });
  const {
    data: dataUserPermissionRights,
    loading: loadingUserPermissionRights,
    error: errorUserPermissionRights
  } = useQuery(QUERY_GET_USER_PERMISSION_RIGHTS, { fetchPolicy: 'network-only', skip: props.open === false });
  const [createUser, { loading: loadingCreateUser }] = useMutation(MUTATION_CREATE_USER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('usersAdminPage.addUserDrawer.createUserFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<AddUserRequest>({
    resolver: zodResolver(addUserSchema),
    defaultValues: { isSuperUser: false, permissions: [], firstName: '', lastName: '', email: '' }
  });

  const userTypeValues = Object.values(UserType);
  const userTypeSelectOptions: RSSelectItemProps[] = userTypeValues.map((item) => ({
    displayName: mapUserType(item),
    menuItemProps: { value: item }
  }));
  const companyTypeValues = Object.values(CompanyType);
  const companyTypeOptions: RSSelectItemProps[] = companyTypeValues.map((type) => ({
    displayName: mapCompanyType(type),
    menuItemProps: { value: type }
  }));
  const companiesResponse = dataCompanyNames?.companies || [];
  const companyOptions: RSSelectItemProps[] = companiesResponse.map((company) => ({
    displayName: company.name,
    menuItemProps: { value: company.id }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenAddUser(false);
  };

  const handleCancel = (): void => {
    if (!isEmpty(dirtyFields)) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenAddUser(false);
    }
  };

  const onSubmit: SubmitHandler<AddUserRequest> = (data): void => {
    const dataToSubmit: CreateUserMutationVariables = omit(data, 'companyType');
    if (isEmpty(dirtyFields)) {
      setOpenAddUser(false);
      setShowConfirmationModal(false);
      return;
    }

    createUser({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(t('usersAdminPage.addUserDrawer.createUserSuccess'), undefined, undefined, 'success');
        reset();
        refetchUsers();
        refetchUserNames();
        refetchCompanyNames();
        setOpenAddUser(false);
        setShowConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/users/${responseData.createUser.id}`);
      }
    });
  };

  const handleCheckboxSelect = (permission: UserPermissionType, newValue?: boolean | null): void => {
    if (newValue === true) {
      setValue('permissions', uniq([...getValues('permissions'), permission]));
      return;
    }
    if (!newValue) {
      setValue(
        'permissions',
        filter(getValues('permissions'), (item) => item !== permission)
      );
      return;
    }
  };

  const groupedErrors = errorUser || errorCompanyNames || errorUserPermissionRights;
  if (groupedErrors) {
    return (
      <RSDrawer {...props} className="add-user-drawer">
        <ModalDrawerHeader title={t('usersAdminPage.addUserDrawer.title')} />
        <div className="add-user-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={groupedErrors.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('usersAdminPage.addUserDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingUser || loadingCompanyNames || loadingUserPermissionRights) {
    return (
      <RSDrawer {...props} className="add-user-drawer">
        <ModalDrawerHeader title={t('usersAdminPage.addUserDrawer.title')} />
        <div className="add-user-drawer__form">
          <div className="add-user-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('usersAdminPage.addUserDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="add-user-drawer">
      <ModalDrawerHeader title={t('usersAdminPage.addUserDrawer.title')} />
      <form className="add-user-drawer__form" onSubmit={handleSubmit(onSubmit)} data-testid="add-user-drawer-form">
        <div className="add-user-drawer__input-fields">
          <Controller
            name="userType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="add-user-drawer__user-type-select"
                inputLabel={t('usersAdminPage.addUserDrawer.userType')}
                required={true}
                menuItems={userTypeSelectOptions}
                helperText={errors.userType?.message}
                error={Boolean(errors.userType)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="add-user-drawer-user-type-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <RSTextInput
            inputLabel={t('usersAdminPage.addUserDrawer.firstName')}
            required={true}
            data-testid="add-user-drawer-first-name-input"
            helperText={errors.firstName?.message}
            error={Boolean(errors.firstName)}
            {...register('firstName')}
          />
          <RSTextInput
            inputLabel={t('usersAdminPage.addUserDrawer.lastName')}
            required={true}
            data-testid="add-user-drawer-last-name-input"
            helperText={errors.lastName?.message}
            error={Boolean(errors.lastName)}
            {...register('lastName')}
          />
          <RSTextInput
            inputLabel={t('usersAdminPage.addUserDrawer.email')}
            required={true}
            data-testid="add-user-drawer-email-input"
            helperText={errors.email?.message}
            error={Boolean(errors.email)}
            {...register('email')}
          />
          <Controller
            name="companyId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="add-user-drawer__company-select"
                inputLabel={t('usersAdminPage.addUserDrawer.company')}
                required={true}
                menuItems={companyOptions}
                helperText={errors.companyId?.message}
                error={Boolean(errors.companyId)}
                onBlur={onBlur}
                onChange={(event) => {
                  onChange(event);
                  const companyType = (companiesResponse.find((item) => item.id === event.target.value)?.companyType ||
                    '') as CompanyType;
                  setValue('companyType', companyType);
                  const updatedAllowedPermissions = getAllowedUserPermissions(dataUserPermissionRights, companyType);
                  setValue(
                    'permissions',
                    getValues('permissions').filter((item) => includes(updatedAllowedPermissions, item))
                  );
                }}
                data-testid="add-user-drawer-company-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <Controller
            name="companyType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="add-user-drawer__company-type-select"
                inputLabel={t('usersAdminPage.addUserDrawer.companyType')}
                required={true}
                disabled={true}
                menuItems={companyTypeOptions}
                helperText={errors.companyType?.message}
                error={Boolean(errors.companyType)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="add-user-drawer-company-type-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          {head(dataUser?.users)?.isSuperUser && (
            <Controller
              control={control}
              name="isSuperUser"
              render={({ field: { onBlur, onChange, value } }) => (
                <RSSwitch
                  formLabel={t('usersAdminPage.addUserDrawer.superUser')}
                  data-testid="add-user-drawer-super-user-switch"
                  checked={Boolean(value)}
                  onBlur={onBlur}
                  onChange={onChange}
                  falseValueLabel={t('usersAdminPage.addUserDrawer.superUserDisabled')}
                  trueValueLabel={t('usersAdminPage.addUserDrawer.superUserEnabled')}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="permissions"
            render={({ field: { onBlur, value } }) => (
              <RSCheckboxGroup
                formLabel={t('usersAdminPage.addUserDrawer.permissions')}
                formLabelProps={{ id: 'permissions' }}
                aria-labelledby="permissions"
                data-testid="user-permissions"
                onBlur={onBlur}
              >
                <RSCheckbox
                  label={t('displayUserPermissionType.companyAdmin')}
                  checked={includes(value, UserPermissionType.CompanyAdmin)}
                  color="success"
                  disabled={
                    !includes(
                      getAllowedUserPermissions(dataUserPermissionRights, watch('companyType')),
                      UserPermissionType.CompanyAdmin
                    )
                  }
                  onChange={(event) => handleCheckboxSelect(UserPermissionType.CompanyAdmin, event.target.checked)}
                  data-testid="user-permission-company-admin"
                />
                <RSCheckbox
                  label={t('displayUserPermissionType.deviceAdmin')}
                  color="success"
                  checked={includes(value, UserPermissionType.DeviceAdmin)}
                  disabled={
                    !includes(
                      getAllowedUserPermissions(dataUserPermissionRights, watch('companyType')),
                      UserPermissionType.DeviceAdmin
                    )
                  }
                  onChange={(event) => handleCheckboxSelect(UserPermissionType.DeviceAdmin, event.target.checked)}
                  data-testid="user-permission-device-admin"
                />
                <RSCheckbox
                  label={t('displayUserPermissionType.deviceImporter')}
                  color="success"
                  checked={includes(value, UserPermissionType.DeviceImporter)}
                  disabled={
                    !includes(
                      getAllowedUserPermissions(dataUserPermissionRights, watch('companyType')),
                      UserPermissionType.DeviceImporter
                    )
                  }
                  onChange={(event) => handleCheckboxSelect(UserPermissionType.DeviceImporter, event.target.checked)}
                  data-testid="user-permission-device-importer"
                />
                <RSCheckbox
                  label={t('displayUserPermissionType.userAdmin')}
                  color="success"
                  disabled={
                    !includes(
                      getAllowedUserPermissions(dataUserPermissionRights, watch('companyType')),
                      UserPermissionType.UserAdmin
                    )
                  }
                  checked={includes(value, UserPermissionType.UserAdmin)}
                  onChange={(event) => handleCheckboxSelect(UserPermissionType.UserAdmin, event.target.checked)}
                  data-testid="user-permission-user-admin"
                />

                <RSCheckbox
                  label={t('displayUserPermissionType.userDeviceGroupAdmin')}
                  color="success"
                  disabled={
                    !includes(
                      getAllowedUserPermissions(dataUserPermissionRights, watch('companyType')),
                      UserPermissionType.UserDeviceGroupAdmin
                    )
                  }
                  checked={includes(value, UserPermissionType.UserDeviceGroupAdmin)}
                  onChange={(event) =>
                    handleCheckboxSelect(UserPermissionType.UserDeviceGroupAdmin, event.target.checked)
                  }
                  data-testid="user-permission-user-device-group-admin"
                />
              </RSCheckboxGroup>
            )}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingCreateUser}
          isCancelDisabled={loadingCreateUser}
          colorVariant="success"
          saveButtonText={t('usersAdminPage.addUserDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};
