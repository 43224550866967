import classNames from 'classnames';
import { ComponentProps, PropsWithChildren } from 'react';

import { DataTestId } from '../../../types';

export interface RSActionLinkProps extends PropsWithChildren, DataTestId, ComponentProps<'span'> {
  extraClassNames?: string[];
  disabled?: boolean;
}

export const RSActionLink = ({
  extraClassNames,
  'data-testid': dataTestId,
  children,
  disabled,
  ...props
}: RSActionLinkProps): JSX.Element => {
  // The purpose of using <span> but not <button>: this element is often used inside a form, where <button> is
  // automatically being focused and results in undesired behavior; or it is used in an accordion, where the top level
  // <RSAccordion> itself is a button - <button> cannot be in a <button>.
  // See also: https://dev.azure.com/rocsys/Cake/_boards/board/t/Crew%20-%20Cloud/Backlog%20items/?workitem=13666
  if (disabled) {
    return (
      <span
        className={classNames('rs-action-link', extraClassNames, 'rs-action-link--disabled')}
        data-testid={dataTestId || 'rs-action-link-disabled'}
      >
        {children}
      </span>
    );
  }

  return (
    <span
      {...props}
      className={classNames('rs-action-link', extraClassNames)}
      data-testid={dataTestId || 'rs-action-link'}
    >
      {children}
    </span>
  );
};
