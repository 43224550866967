import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { endsWith, filter, head, includes, isEqual, uniq } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModifyUserRequest, modifyUserSchema } from './validation-schema';
import { UserPermissionType } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker';
import { MUTATION_DELETE_USER, MUTATION_MODIFY_USER } from '../../../../services/mutations';
import { QUERY_GET_USER_AUTH_INFO, QUERY_GET_USER_PERMISSION_RIGHTS } from '../../../../services/queries';
import { CompanyType } from '../../../../types';
import { getAllowedUserPermissions } from '../../../../utilities';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { Loading, RSCheckbox, RSCheckboxGroup, RSSelect, RSSwitch, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';
import { ErrorPage } from '../../error-page';

interface ModifyUserDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenModifyUser: Dispatch<SetStateAction<boolean>>;
  defaultValues: ModifyUserRequest;
  companyType: CompanyType;
  companyName: string;
  companyId: string;
  userType: string;
  email: string;
}

export const ModifyUserDrawer = ({
  setOpenModifyUser,
  companyType,
  companyName,
  companyId,
  email,
  userType,
  defaultValues,
  ...props
}: ModifyUserDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);
  const { result: canUserDeleteUser, loading: loadingCanUserDeleteUser } = useAuthCheckerWithSubjectInfo({
    action: 'DELETE',
    subjectInfo: {
      type: 'User',
      email: email,
      companyId: companyId,
      isSuperUser: defaultValues.isSuperUser,
      id: defaultValues.id,
      permissions: defaultValues.permissions
    },
    skip: false
  });
  const {
    data: dataUserPermissionRights,
    loading: loadingUserPermissionRights,
    error: errorUserPermissionRights
  } = useQuery(QUERY_GET_USER_PERMISSION_RIGHTS, { fetchPolicy: 'network-only', skip: props.open === false });
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: props.open === false || !user?.sub
  });
  const [modifyUser, { loading: loadingModifyUser }] = useMutation(MUTATION_MODIFY_USER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error')
  });
  const [deleteUser, { loading: loadingDeleteUser }] = useMutation(MUTATION_DELETE_USER, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('forms.snackbarMessages.errorDelete', { entity: t('entities.user') }),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm<ModifyUserRequest>({
    resolver: zodResolver(modifyUserSchema),
    defaultValues
  });

  const handleCheckboxSelect = (permission: UserPermissionType, newValue?: boolean | null): void => {
    if (newValue === true) {
      setValue('permissions', uniq([...getValues('permissions'), permission]));
      return;
    }
    if (!newValue) {
      setValue(
        'permissions',
        filter(getValues('permissions'), (item) => item !== permission)
      );
      return;
    }
  };

  const handleLeave = (): void => {
    reset();
    setShowLeaveConfirmationModal(false);
    setOpenModifyUser(false);
  };

  const handleCancel = (): void => {
    const currentFormValues = getValues();
    if (!isEqual(currentFormValues, defaultValues)) {
      setShowLeaveConfirmationModal(true);
    } else {
      reset();
      setOpenModifyUser(false);
    }
  };

  const onSubmit: SubmitHandler<ModifyUserRequest> = (data): void => {
    const currentFormValues = getValues();
    if (isEqual(currentFormValues, defaultValues)) {
      setOpenModifyUser(false);
      setShowLeaveConfirmationModal(false);
      return;
    }

    modifyUser({
      variables: data,
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        reset(data);
        setOpenModifyUser(false);
        setShowLeaveConfirmationModal(false);
      }
    });
  };

  const onDelete = (): void => {
    deleteUser({
      variables: { id: defaultValues.id },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.successfullyDeleted', { entity: t('entities.user') }),
          undefined,
          undefined,
          'success'
        );
        setOpenModifyUser(false);
        setShowDeleteConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/users`);
      }
    });
  };

  const groupedError = errorUserPermissionRights || errorUser;
  if (groupedError) {
    return (
      <RSDrawer {...props} className="modify-user-drawer">
        <ModalDrawerHeader title={t('userAdminDetailsPage.modifyUserDrawer.title')} />
        <div className="modify-user-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={groupedError.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingUserPermissionRights || loadingUser || loadingCanUserDeleteUser) {
    return (
      <RSDrawer {...props} className="modify-user-drawer">
        <ModalDrawerHeader title={t('userAdminDetailsPage.modifyUserDrawer.title')} />
        <div className="modify-user-drawer__form">
          <div className="modify-user-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="modify-user-drawer">
      <ModalDrawerHeader title={t('userAdminDetailsPage.modifyUserDrawer.title')} />
      {dataUserPermissionRights && dataUser && (
        <>
          <form
            className="modify-user-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="modify-user-drawer-form"
          >
            <div className="modify-user-drawer__input-fields">
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onBlur, onChange, value } }) => (
                  <RSTextInput
                    inputLabel={t('userAdminDetailsPage.modifyUserDrawer.firstName')}
                    required={true}
                    data-testid="modify-user-drawer-first-name-input"
                    helperText={errors.firstName?.message}
                    error={Boolean(errors.firstName)}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onBlur, onChange, value } }) => (
                  <RSTextInput
                    inputLabel={t('userAdminDetailsPage.modifyUserDrawer.lastName')}
                    required={true}
                    data-testid="modify-user-drawer-last-name-input"
                    helperText={errors.lastName?.message}
                    error={Boolean(errors.lastName)}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <RSTextInput
                inputLabel={t('userAdminDetailsPage.modifyUserDrawer.email')}
                required={true}
                data-testid="modify-user-drawer-email-input"
                disabled={true}
                defaultValue={email}
              />
              <RSSelect
                className="modify-user-drawer__user-type-select"
                inputLabel={t('userAdminDetailsPage.modifyUserDrawer.userType')}
                menuItems={[{ displayName: userType, menuItemProps: { value: userType } }]}
                disabled={true}
                data-testid="modify-user-drawer-user-type-select"
                defaultValue={userType}
              />
              <RSSelect
                className="modify-user-drawer__company-name-select"
                inputLabel={t('userAdminDetailsPage.modifyUserDrawer.companyName')}
                menuItems={[{ displayName: companyName, menuItemProps: { value: companyName } }]}
                disabled={true}
                data-testid="modify-user-drawer-company-name-select"
                defaultValue={companyName}
              />
              {endsWith(head(dataUser?.users)?.email, '@rocsys.com') && (
                <Controller
                  control={control}
                  name="isSuperUser"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <RSSwitch
                      formLabel={t('userAdminDetailsPage.modifyUserDrawer.superUser')}
                      data-testid="modify-user-drawer-super-user-switch"
                      checked={Boolean(value)}
                      onBlur={onBlur}
                      onChange={onChange}
                      falseValueLabel={t('userAdminDetailsPage.modifyUserDrawer.superUserDisabled')}
                      trueValueLabel={t('userAdminDetailsPage.modifyUserDrawer.superUserEnabled')}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name="permissions"
                render={({ field: { onBlur, value } }) => (
                  <RSCheckboxGroup
                    formLabel={t('userAdminDetailsPage.modifyUserDrawer.permissions')}
                    formLabelProps={{ id: 'permissions' }}
                    aria-labelledby="permissions"
                    data-testid="user-permissions"
                    onBlur={onBlur}
                  >
                    <RSCheckbox
                      label={t('displayUserPermissionType.companyAdmin')}
                      checked={includes(value, UserPermissionType.CompanyAdmin)}
                      color="success"
                      disabled={
                        !includes(
                          getAllowedUserPermissions(dataUserPermissionRights, companyType),
                          UserPermissionType.CompanyAdmin
                        )
                      }
                      onChange={(event) => handleCheckboxSelect(UserPermissionType.CompanyAdmin, event.target.checked)}
                      data-testid="user-permission-company-admin"
                    />
                    <RSCheckbox
                      label={t('displayUserPermissionType.deviceAdmin')}
                      color="success"
                      checked={includes(value, UserPermissionType.DeviceAdmin)}
                      disabled={
                        !includes(
                          getAllowedUserPermissions(dataUserPermissionRights, companyType),
                          UserPermissionType.DeviceAdmin
                        )
                      }
                      onChange={(event) => handleCheckboxSelect(UserPermissionType.DeviceAdmin, event.target.checked)}
                      data-testid="user-permission-device-admin"
                    />
                    <RSCheckbox
                      label={t('displayUserPermissionType.deviceImporter')}
                      color="success"
                      checked={includes(value, UserPermissionType.DeviceImporter)}
                      disabled={
                        !includes(
                          getAllowedUserPermissions(dataUserPermissionRights, companyType),
                          UserPermissionType.DeviceImporter
                        )
                      }
                      onChange={(event) =>
                        handleCheckboxSelect(UserPermissionType.DeviceImporter, event.target.checked)
                      }
                      data-testid="user-permission-device-importer"
                    />
                    <RSCheckbox
                      label={t('displayUserPermissionType.userAdmin')}
                      color="success"
                      disabled={
                        !includes(
                          getAllowedUserPermissions(dataUserPermissionRights, companyType),
                          UserPermissionType.UserAdmin
                        )
                      }
                      checked={includes(value, UserPermissionType.UserAdmin)}
                      onChange={(event) => handleCheckboxSelect(UserPermissionType.UserAdmin, event.target.checked)}
                      data-testid="user-permission-user-admin"
                    />
                    <RSCheckbox
                      label={t('displayUserPermissionType.userDeviceGroupAdmin')}
                      color="success"
                      disabled={
                        !includes(
                          getAllowedUserPermissions(dataUserPermissionRights, companyType),
                          UserPermissionType.UserDeviceGroupAdmin
                        )
                      }
                      checked={includes(value, UserPermissionType.UserDeviceGroupAdmin)}
                      onChange={(event) =>
                        handleCheckboxSelect(UserPermissionType.UserDeviceGroupAdmin, event.target.checked)
                      }
                      data-testid="user-permission-user-device-group-admin"
                    />
                  </RSCheckboxGroup>
                )}
              />
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              handleDelete={() => setShowDeleteConfirmationModal(true)}
              isSaveDisabled={loadingModifyUser}
              isCancelDisabled={loadingModifyUser}
              isDeleteDisabled={!canUserDeleteUser || loadingModifyUser || loadingCanUserDeleteUser}
              deleteButtonText={t('userAdminDetailsPage.modifyUserDrawer.deleteUser')}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showLeaveConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowLeaveConfirmationModal(false)}
            disableConfirmButton={loadingModifyUser}
            disableCancelButton={loadingModifyUser}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
          <ConfirmationModal
            open={showDeleteConfirmationModal}
            mainTitle={t('forms.deleteModal.title')}
            message={t('forms.deleteModal.message', { entity: t('entities.user') })}
            confirmButtonText={
              loadingDeleteUser ? t('forms.deleteModal.confirmDeleting') : t('forms.deleteModal.confirm')
            }
            cancelButtonText={t('forms.deleteModal.cancel')}
            handleClickCancelButton={() => setShowDeleteConfirmationModal(false)}
            handleClickConfirmButton={onDelete}
            disableConfirmButton={loadingDeleteUser}
            disableCancelButton={loadingDeleteUser}
            confirmButtonColor="error"
            cancelButtonColor="error"
          />
        </>
      )}
    </RSDrawer>
  );
};
