import classNames from 'classnames';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricChangeIcon, RSTooltip } from '../../.';
import { MetricChangeData, MetricData } from '../../../../types';
import { getMetricChangeValue, getMetricValue } from '../../../../utilities';
import { UserCompanyTypeContext } from '../../../contexts';

interface DataGridPerformanceMetricCellProps {
  metric: MetricData;
  metricChange: MetricChangeData;
  metricCount: {
    value: number | null;
    displayUnit: string;
  };
}

type MetricChangeProps = Pick<DataGridPerformanceMetricCellProps, 'metricChange'>;

const DataGridPerformanceMetricChange = ({ metricChange }: MetricChangeProps): JSX.Element => {
  const {
    showServicePortal,
    loading: customerContextLoading,
    error: customerContextError
  } = useContext(UserCompanyTypeContext);

  if (customerContextLoading || customerContextError) {
    return <></>;
  }
  return (
    <>
      <MetricChangeIcon value={metricChange.value} showServicePortal={showServicePortal} />
      <p
        className={classNames(
          'data-grid-performance-metric-cell__metric-change-value',
          showServicePortal
            ? {
                'data-grid-performance-metric-cell__metric-change-value--neutral':
                  metricChange.value === 0 || metricChange.value === null || metricChange.value === undefined,
                'data-grid-performance-metric-cell__metric-change-value--positive':
                  metricChange.value !== null && metricChange.value !== undefined && metricChange.value > 0,
                'data-grid-performance-metric-cell__metric-change-value--negative':
                  metricChange.value !== null && metricChange.value !== undefined && metricChange.value < 0
              }
            : { 'data-grid-performance-metric-cell__metric-change-value--neutral': true }
        )}
        data-testid="data-grid-performance-metric-cell__metric-change-value"
      >
        {getMetricChangeValue(metricChange.value, metricChange.decimalPlaces)}
        {metricChange?.displayUnit}
      </p>
    </>
  );
};

export const DataGridPerformanceMetricCell = ({
  metric,
  metricChange,
  metricCount
}: DataGridPerformanceMetricCellProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <RSTooltip title={t('dataGrid.operationalPerformance.tooltips.performance')}>
      <div className="data-grid-performance-metric-cell" data-testid="data-grid-performance-metric-cell">
        <p
          className="data-grid-performance-metric-cell__metric-value"
          data-testid="data-grid-performance-metric-cell__metric-value"
        >
          {getMetricValue(metric.value, metric.decimalPlaces)}
          {metric.displayUnit}
        </p>
        <DataGridPerformanceMetricChange metricChange={metricChange} />
        <p
          className="data-grid-performance-metric-cell__metric-count-value"
          data-testid="data-grid-performance-metric-cell__metric-count-value"
        >
          {metricCount.value}
          {metricCount.displayUnit}
        </p>
      </div>
    </RSTooltip>
  );
};
