import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminDeviceGroupDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker';
import { getNonNullishDisplayValue } from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';
import { ModifyDeviceGroupDrawer } from '../modify-device-group-drawer';

interface DeviceGroupDetailsAdminPanelProps {
  // This panel will only be shown if the site details are successfully loaded
  deviceGroupDetails: NonNullable<GetAdminDeviceGroupDetailsQuery['deviceGroupByPK']>;
}

export const DeviceGroupDetailsAdminPanel = ({
  deviceGroupDetails
}: DeviceGroupDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const [openModifyDeviceGroup, setOpenModifyDeviceGroup] = useState<boolean>(false);
  const { result: userCanEditDeviceGroup, loading: loadingUserCanEditDeviceGroup } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: {
      type: 'DeviceGroup',
      customerId: deviceGroupDetails.customer.companyId,
      serviceProviderId: deviceGroupDetails.serviceProvider?.companyId,
      deviceGroupId: deviceGroupDetails.id
    },
    skip: false
  });

  return (
    <aside className="device-group-details-admin-panel" data-testid="device-group-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('deviceGroupDetailsPage.panel.type')}
        objectName={deviceGroupDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies/${deviceGroupDetails.customer.companyId}`}
        editButtonProps={{
          onClick: () => setOpenModifyDeviceGroup(true),
          disabled: loadingUserCanEditDeviceGroup || !userCanEditDeviceGroup
        }}
      />
      <div className="device-group-details-admin-panel__contents">
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.customer')}
          data-testid="device-group-details-admin-panel-customer"
          value={deviceGroupDetails.customer.company.name}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.serviceProvider')}
          data-testid="device-group-details-admin-panel-service-provider"
          value={getNonNullishDisplayValue(deviceGroupDetails.serviceProvider?.company.name)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.description')}
          data-testid="device-group-details-admin-panel-description"
          value={getNonNullishDisplayValue(deviceGroupDetails.description, true)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.createdAt')}
          data-testid="device-group-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(deviceGroupDetails.createdAt)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.updatedAt')}
          data-testid="device-group-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(deviceGroupDetails.updatedAt)}
        />
      </div>
      <ModifyDeviceGroupDrawer
        open={openModifyDeviceGroup}
        createdAt={formatWithDefaultTimezone(deviceGroupDetails.createdAt)}
        customerId={deviceGroupDetails.customer.companyId}
        setOpenModifyDeviceGroup={setOpenModifyDeviceGroup}
        defaultValues={{
          id: deviceGroupDetails.id,
          serviceProviderId: deviceGroupDetails.serviceProvider?.companyId,
          name: deviceGroupDetails.name,
          description: deviceGroupDetails.description
        }}
      />
    </aside>
  );
};
