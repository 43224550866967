import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';

import { MetricChangeData, MetricLoadingError } from '../../../types';
import { getMetricChangeValue } from '../../../utilities';
import { MetricChangeIcon } from '../../5-elements';
import { UserCompanyTypeContext } from '../../contexts';

interface MetricChangeProps extends MetricLoadingError {
  metricChange: MetricChangeData;
}

export const MetricChange = ({ metricChange, hasError, loading }: MetricChangeProps): JSX.Element => {
  const {
    showServicePortal,
    loading: customerContextLoading,
    error: customerContextError
  } = useContext(UserCompanyTypeContext);

  if (loading || customerContextLoading) {
    return (
      <Skeleton variant="text" className="metric-change-value__loading" data-testid="metric-change-value-loading" />
    );
  }

  if (hasError || customerContextError) {
    return <></>;
  }

  return (
    <>
      <MetricChangeIcon value={metricChange.value} showServicePortal={showServicePortal} />
      <p
        className={classNames(
          'metric-change-value',
          showServicePortal
            ? {
                'metric-change-value--neutral':
                  metricChange.value === 0 || metricChange.value === null || metricChange.value === undefined,
                'metric-change-value--positive':
                  metricChange.value !== null && metricChange.value !== undefined && metricChange.value > 0,
                'metric-change-value--negative':
                  metricChange.value !== null && metricChange.value !== undefined && metricChange.value < 0
              }
            : { 'metric-change-value--neutral': true }
        )}
        data-testid="metric-change-value"
      >
        {getMetricChangeValue(metricChange.value, metricChange.decimalPlaces)}
        {metricChange.displayUnit}
      </p>
    </>
  );
};
