import { useQuery } from '@apollo/client';
import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AddDeviceGroupDrawer } from './add-device-group-drawer';
import { AddSiteDrawer } from './add-site-drawer';
import { CompanyDetailsAdminPanel } from './company-details-admin-panel';
import {
  companyDeviceGroupsDataGridColumns,
  companySitesDataGridColumns,
  generateDeviceGroupRows,
  generateSiteRows
} from './data-grid-configurations';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker';
import { QUERY_GET_ADMIN_COMPANY_DETAILS } from '../../../services/queries';
import { CompanyType } from '../../../types';
import { AdminDetailsMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminDetailsDataGrid } from '../../4-features';
import { ErrorPage, PageNotFoundErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';

export const CompanyDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [openAddSite, setOpenAddSite] = useState<boolean>(false);
  const [openAddDeviceGroup, setOpenAddDeviceGroup] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_COMPANY_DETAILS, {
    variables: { companyId: companyId! },
    fetchPolicy: 'network-only'
  });
  const { result: canUserCreateSite, loading: loadingCanUserCreateSite } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'Site', customerId: companyId! },
    skip: false
  });
  const { result: canUserCreateDeviceGroup, loading: loadingCanUserCreateDeviceGroup } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'DeviceGroup', customerId: companyId! },
    skip: false
  });

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const companyDetails = data?.companyByPK;
  if (!companyDetails) {
    return <PageNotFoundErrorPage />;
  }

  const isCustomer = companyDetails.companyType === CompanyType.Customer;
  const deviceGroups = companyDetails.customer?.deviceGroups || companyDetails.serviceProvider?.deviceGroups;

  return (
    <AdminDetailsMainSectionWrapper panel={<CompanyDetailsAdminPanel companyDetails={companyDetails} />}>
      <div className="company-details-admin-page" data-testid="company-details-admin-page">
        {isCustomer && (
          <div className="company-details-admin-page__sites" data-testid="company-details-admin-page-sites">
            <div className="company-details-admin-page__title-bar">
              <h2>{t('companyAdminDetailsPage.sitesDataGrid.title')}</h2>
              <AdminAddActionButton
                titleName={t('companyAdminDetailsPage.sitesDataGrid.add')}
                disabled={!canUserCreateSite || loadingCanUserCreateSite}
                data-testid="company-details-admin-page-add-site"
                onClick={() => setOpenAddSite(true)}
              />
            </div>
            <div className="company-details-admin-page__data-grid">
              <AdminDetailsDataGrid
                columns={companySitesDataGridColumns}
                loading={loading}
                rows={generateSiteRows(companyDetails.customer!.sites)}
                data-testid="company-details-admin-page-sites-data-grid"
              />
            </div>
          </div>
        )}
        <div className="company-details-admin-page__device-groups">
          <div className="company-details-admin-page__title-bar">
            <h2>{t('companyAdminDetailsPage.deviceGroupsDataGrid.title')}</h2>
            <AdminAddActionButton
              titleName={t('companyAdminDetailsPage.deviceGroupsDataGrid.add')}
              disabled={!canUserCreateDeviceGroup || loadingCanUserCreateDeviceGroup}
              data-testid="company-details-admin-page-add-device-group"
              onClick={() => setOpenAddDeviceGroup(true)}
            />
          </div>
          <div className="company-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={companyDeviceGroupsDataGridColumns(companyDetails.companyType as CompanyType)}
              loading={loading}
              rows={generateDeviceGroupRows(companyDetails.companyType as CompanyType, deviceGroups!)}
              data-testid="company-details-admin-page-customer-device-groups-data-grid"
            />
          </div>
        </div>
      </div>
      {isCustomer && (
        <AddSiteDrawer
          customerName={companyDetails.name}
          customerId={companyDetails.id}
          open={openAddSite}
          setOpenAddSite={setOpenAddSite}
          refetchCompany={refetch}
        />
      )}
      <AddDeviceGroupDrawer
        customerName={companyDetails.name}
        customerId={companyDetails.id}
        open={openAddDeviceGroup}
        setOpenAddDeviceGroup={setOpenAddDeviceGroup}
        refetchCompany={refetch}
      />
    </AdminDetailsMainSectionWrapper>
  );
};
