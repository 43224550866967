import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { CustomersPageContent } from './customers-page-content';
import { appConfig } from '../../../configs';
import { UserCompanyTypeContext, UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';

export const CustomersPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { showServicePortal, companyId, error } = useContext(UserCompanyTypeContext);

  if (error) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (showServicePortal === true) {
    return <CustomersPageContent />;
  }

  if (showServicePortal === false && companyId) {
    return <Navigate to={`${appConfig.basePath}/customers/${companyId}?timezone=${userTimezone}`} replace={true} />;
  }

  return <LoadingPage />;
};
