import { ApolloError, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { head } from 'lodash';
import { JSX, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QUERY_GET_USER_AUTH_INFO } from '../../services/queries';
import { browserTimezone } from '../../utilities';
import {
  AutoRefreshContext,
  AutoRefreshContextInterface,
  UserCompanyTypeContext,
  UserTimezoneContext,
  UserTimezoneContextInterface
} from '../contexts';

export const ContextWrapper = (props: PropsWithChildren): JSX.Element => {
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const autoRefreshValue: AutoRefreshContextInterface = { autoRefresh, setAutoRefresh };
  const [userTimezone, setUserTimezone] = useState<string>(browserTimezone);
  const timezoneValue: UserTimezoneContextInterface = { userTimezone, setUserTimezone };
  const { user } = useAuth0();
  const { t } = useTranslation();
  const {
    data: dataUserAuthInfo,
    error: errorUserAuthInfo,
    loading: loadingUserAuthInfo
  } = useQuery(QUERY_GET_USER_AUTH_INFO, { variables: { userAuthId: user?.sub || '' }, skip: !user?.sub });

  const userAuthInfo = head(dataUserAuthInfo?.users);
  const showServicePortal = userAuthInfo && (userAuthInfo.isSuperUser || Boolean(userAuthInfo.company.serviceProvider));

  const companyId = head(dataUserAuthInfo?.users)?.companyId;
  let error: Error | ApolloError | undefined = errorUserAuthInfo;
  // we have no company but query finished loading successfully
  // this means the user is not found
  if (!companyId && !errorUserAuthInfo && !loadingUserAuthInfo) {
    error = new Error(t('errors.userNotFound.message'));
  }

  return (
    <AutoRefreshContext.Provider value={autoRefreshValue}>
      <UserTimezoneContext.Provider value={timezoneValue}>
        <UserCompanyTypeContext.Provider value={{ showServicePortal, companyId, error, loading: loadingUserAuthInfo }}>
          {props.children}
        </UserCompanyTypeContext.Provider>
      </UserTimezoneContext.Provider>
    </AutoRefreshContext.Provider>
  );
};
