import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModifySiteRequest, modifySiteSchema } from './validation-schema/modify-site-schema';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker';
import { MUTATION_DELETE_SITE, MUTATION_MODIFY_SITE } from '../../../../services/mutations';
import { QUERY_GET_TIME_ZONES } from '../../../../services/queries';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { Loading, RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';
import { ErrorPage } from '../../error-page';

interface ModifySiteDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenModifySite: Dispatch<SetStateAction<boolean>>;
  defaultValues: ModifySiteRequest;
  customerId: string;
  customerName: string;
  createdAt: string;
}

export const ModifySiteDrawer = ({
  setOpenModifySite,
  defaultValues,
  customerId,
  customerName,
  createdAt,
  ...props
}: ModifySiteDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingTimezones,
    data: dataTimezones,
    error: errorTimezones
  } = useQuery(QUERY_GET_TIME_ZONES, { fetchPolicy: 'no-cache', skip: props.open === false });
  const { result: userCanDeleteSite, loading: loadingUserCanDeleteSite } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: { type: 'Site', customerId: customerId },
    skip: props.open === false
  });
  const [modifySite, { loading: loadingModifySite }] = useMutation(MUTATION_MODIFY_SITE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error')
  });
  const [deleteSite, { loading: loadingDeleteSite }] = useMutation(MUTATION_DELETE_SITE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      if (error.message.includes('Foreign key constraint')) {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.errorDelete', { entity: t('entities.site') }),
          undefined,
          t('siteAdminDetailsPage.modifySiteDrawer.siteContainsDevicesOrUsers'),
          'error'
        );
      } else {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.errorDelete', { entity: t('entities.site') }),
          undefined,
          error.message || error.name,
          'error'
        );
      }
      setShowDeleteConfirmationModal(false);
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors, isDirty }
  } = useForm<ModifySiteRequest>({
    resolver: zodResolver(modifySiteSchema),
    defaultValues
  });

  const TIMEZONE_AUTO_DETECT = { name: t('siteAdminDetailsPage.modifySiteDrawer.autoDetect') };
  const customerSelectOption: RSSelectItemProps = { displayName: customerName, menuItemProps: { value: customerId } };
  const timeZonesResponse = dataTimezones?.timezones || [];
  const timeZoneOptions: RSSelectItemProps[] = [TIMEZONE_AUTO_DETECT, ...timeZonesResponse].map((timeZone) => {
    return { displayName: timeZone.name, menuItemProps: { value: timeZone.name } };
  });

  const onDelete = (): void => {
    deleteSite({
      variables: { id: defaultValues.id },
      onCompleted: () => {
        sendMessageToSnackbar(
          t('forms.snackbarMessages.successfullyDeleted', { entity: t('entities.site') }),
          undefined,
          undefined,
          'success'
        );
        setOpenModifySite(false);
        setShowDeleteConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/companies/${customerId}`);
      }
    });
  };

  const onSubmit: SubmitHandler<ModifySiteRequest> = (data): void => {
    if (!isDirty) {
      setOpenModifySite(false);
      setShowLeaveConfirmationModal(false);
      return;
    }

    modifySite({
      variables: { ...data, timezone: data.timezone === TIMEZONE_AUTO_DETECT.name ? undefined : data.timezone },
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        reset(data);
        setOpenModifySite(false);
        setShowLeaveConfirmationModal(false);
      }
    });
  };

  const handleLeave = (): void => {
    reset();
    setShowLeaveConfirmationModal(false);
    setOpenModifySite(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowLeaveConfirmationModal(true);
    } else {
      reset();
      setOpenModifySite(false);
    }
  };

  if (errorTimezones) {
    return (
      <RSDrawer {...props} className="modify-site-drawer">
        <ModalDrawerHeader title={t('siteAdminDetailsPage.modifySiteDrawer.title')} />
        <div className="modify-site-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={errorTimezones.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingUserCanDeleteSite || loadingTimezones) {
    return (
      <RSDrawer {...props} className="modify-site-drawer">
        <ModalDrawerHeader title={t('siteAdminDetailsPage.modifySiteDrawer.title')} />
        <div className="modify-site-drawer__form">
          <div className="modify-site-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="modify-site-drawer">
      <ModalDrawerHeader title={t('siteAdminDetailsPage.modifySiteDrawer.title')} />

      <form
        className="modify-site-drawer__form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="modify-site-drawer-form"
      >
        <div className="modify-site-drawer__input-fields">
          <Controller
            control={control}
            name="name"
            render={({ field: { onBlur, onChange, value } }) => (
              <RSTextInput
                inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.name')}
                required={true}
                data-testid="modify-site-drawer-site-name-input"
                helperText={errors.name?.message}
                error={Boolean(errors.name)}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <RSSelect
            className="modify-site-drawer__customer-select"
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.customer')}
            required={true}
            disabled={true}
            menuItems={[customerSelectOption]}
            data-testid="modify-site-drawer-customer-select"
            defaultValue={customerId}
            {...RS_SELECT_ADMIN_MENU_PROPS}
          />
          <RSTextInput
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.formattedAddress')}
            data-testid="modify-site-drawer-address-input"
            helperText={errors.location?.formattedAddress?.message}
            error={Boolean(errors.location?.formattedAddress)}
            defaultValue={defaultValues.location?.formattedAddress}
            {...register('location.formattedAddress', { setValueAs: (value) => (value === '' ? undefined : value) })}
          />
          <RSTextInput
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.latitude')}
            data-testid="modify-site-drawer-latitude-input"
            helperText={errors.location?.latitude?.message}
            error={Boolean(errors.location?.latitude)}
            defaultValue={defaultValues.location?.latitude}
            {...register('location.latitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT.name)
            })}
          />
          <RSTextInput
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.longitude')}
            data-testid="modify-site-drawer-longitude-input"
            helperText={errors.location?.longitude?.message}
            error={Boolean(errors.location?.longitude)}
            defaultValue={defaultValues.location?.longitude}
            {...register('location.longitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT.name)
            })}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="modify-site-drawer__timezone-select"
                inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.timezone')}
                required={true}
                menuItems={timeZoneOptions}
                helperText={errors.timezone?.message}
                error={Boolean(errors.timezone)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="modify-site-drawer-timezone-select"
                value={value || TIMEZONE_AUTO_DETECT.name}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <RSTextInput
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.description')}
            data-testid="modify-site-drawer-description-input"
            multiline={true}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            defaultValue={defaultValues.description}
            {...register('description', { setValueAs: (value) => (value === '' ? undefined : value) })}
          />
          <RSTextInput
            inputLabel={t('siteAdminDetailsPage.modifySiteDrawer.createdAt')}
            data-testid="modify-site-drawer-created-at-input"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            disabled={true}
            defaultValue={createdAt}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={() => setShowDeleteConfirmationModal(true)}
          isSaveDisabled={loadingModifySite}
          isCancelDisabled={loadingModifySite}
          isDeleteDisabled={loadingDeleteSite || !userCanDeleteSite}
          colorVariant="success"
          deleteButtonText={t('siteAdminDetailsPage.modifySiteDrawer.deleteSite')}
        />
      </form>
      <ConfirmationModal
        open={showLeaveConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowLeaveConfirmationModal(false)}
        disableConfirmButton={loadingModifySite}
        disableCancelButton={loadingModifySite}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
      <ConfirmationModal
        open={showDeleteConfirmationModal}
        mainTitle={t('forms.deleteModal.title')}
        message={t('forms.deleteModal.message', { entity: t('entities.site') })}
        confirmButtonText={loadingDeleteSite ? t('forms.deleteModal.confirmDeleting') : t('forms.deleteModal.confirm')}
        cancelButtonText={t('forms.deleteModal.cancel')}
        handleClickCancelButton={() => setShowDeleteConfirmationModal(false)}
        handleClickConfirmButton={onDelete}
        disableConfirmButton={loadingDeleteSite}
        disableCancelButton={loadingDeleteSite}
        confirmButtonColor="error"
        cancelButtonColor="error"
      />
    </RSDrawer>
  );
};
