import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteImpressionPlaceholder } from './site-impression-placeholder';
import { ImageType } from '../../../../__generated__/graphql';
import TrashBinIcon from '../../../../assets/icons/trash-bin.svg?react';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_REMOVE_DEVICE_IMAGE } from '../../../../services/mutations';
import { QUERY_GET_DEVICE_IMAGE_URLS } from '../../../../services/queries';
import { useEnqueueSnackbar } from '../../../hooks';
import { SiteImpressionModal } from '../../blob-modal';
import { ConfirmationModal } from '../../confirmation-modal';

interface SiteImpressionImageProps {
  deviceId: string;
  deviceImageId?: string;
  deletable: boolean;
  setDeviceImageId: Dispatch<SetStateAction<string | undefined>>;
}

export const SiteImpressionImage = ({
  deviceId,
  deviceImageId,
  deletable,
  setDeviceImageId
}: SiteImpressionImageProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [fullSizeImageUrl, setFullSizeImageUrl] = useState<string | undefined>(undefined);
  const { data, loading, error } = useQuery(QUERY_GET_DEVICE_IMAGE_URLS, {
    // The query will be skipped if there is no imageId; the '' after the OR logical operator is used for typing fix.
    variables: { ids: [deviceImageId || ''], imageType: ImageType.Thumbnail },
    skip: !deviceImageId,
    fetchPolicy: 'no-cache'
  });
  const [getFullSizeImage, { loading: loadingGetFullSizeImage }] = useLazyQuery(QUERY_GET_DEVICE_IMAGE_URLS);
  const [deleteDeviceImage, { loading: loadingDeleteImage }] = useMutation(MUTATION_REMOVE_DEVICE_IMAGE, {
    context: { timeout: graphqlApiConfig.mutationTimeout }
  });

  // Clicking the thumbnail image will open a modal with its full-resolution version.
  const handleImageClick = (event: MouseEvent): void => {
    event.preventDefault();
    setOpenImageModal(true);

    getFullSizeImage({
      // If the component could ever reach this method, it must have an image ID.
      variables: { ids: [deviceImageId!], imageType: ImageType.FullSize },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => setFullSizeImageUrl(data.requestDeviceImageUrls[0].url),
      onError: (error) => {
        setOpenConfirmationModal(false);
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.siteImpression.errors.requestFullSizeImage'),
          error.name,
          error.message,
          'error'
        );
      }
    });
  };

  const handleDeleteClick = (): void => {
    deleteDeviceImage({
      variables: { id: deviceImageId!, deviceId },
      onCompleted: () => {
        setDeviceImageId(() => undefined);
        setOpenConfirmationModal(false);
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.siteImpression.success.imageDeleted'),
          undefined,
          undefined,
          'success'
        );
      },
      onError: (error) => {
        setOpenConfirmationModal(false);
        sendMessageToSnackbar(
          t('deviceDetailsPage.sidePanel.siteImpression.errors.deviceImageDelete'),
          error.name,
          error.message,
          'error'
        );
      }
    });
  };

  if (!deviceImageId) {
    return <SiteImpressionPlaceholder />;
  }

  if (loading) {
    return <SiteImpressionPlaceholder isLoading={true} />;
  }

  if (error || !data || isEmpty(data.requestDeviceImageUrls)) {
    return <SiteImpressionPlaceholder message={t('deviceDetailsPage.sidePanel.siteImpression.errors.loadingImage')} />;
  }

  const imageUrl = data.requestDeviceImageUrls[0].url;

  // The image will only be rendered if deviceImageId is given, otherwise the placeholder will be rendered.
  return (
    <div
      className="site-impression-image"
      data-testid="site-impression-image-container"
      onMouseOver={() => deletable && setShowDeleteButton(true)}
      onMouseLeave={() => deletable && setShowDeleteButton(false)}
    >
      {deletable && showDeleteButton && (
        <button
          className="site-impression-image__delete-button"
          data-testid="site-impression-delete-button"
          onClick={() => setOpenConfirmationModal(true)}
        >
          <div className="site-impression-image__trash-bin">
            <TrashBinIcon />
          </div>
        </button>
      )}
      <img
        onClick={handleImageClick}
        className="site-impression-image__thumbnail"
        data-testid="site-impression-thumbnail"
        title={t('deviceDetailsPage.sidePanel.siteImpression.image.title')}
        src={imageUrl}
      />
      <SiteImpressionModal
        imageUrl={fullSizeImageUrl}
        isLoading={loadingGetFullSizeImage}
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        handleClose={() => setOpenImageModal(false)}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        mainTitle={t('forms.deleteModal.title')}
        message={t('forms.deleteModal.message', { entity: t('entities.image') })}
        confirmButtonText={loadingDeleteImage ? t('forms.deleteModal.confirmDeleting') : t('forms.deleteModal.confirm')}
        cancelButtonText={t('forms.deleteModal.cancel')}
        disableCancelButton={loadingDeleteImage}
        disableConfirmButton={loadingDeleteImage}
        handleClickConfirmButton={handleDeleteClick}
        handleClickCancelButton={() => setOpenConfirmationModal(false)}
      />
    </div>
  );
};
