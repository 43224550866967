import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import classNames from 'classnames';
import { JSX, ReactNode } from 'react';

import { DataTestId } from '../../../types';

interface RSCheckbox extends CheckboxProps, DataTestId {
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'> & DataTestId;
  label: ReactNode;
}

export const RSCheckbox = ({ formControlLabelProps, label, ...props }: RSCheckbox): JSX.Element => {
  return (
    <FormControlLabel
      {...formControlLabelProps}
      className={classNames('rs-checkbox', formControlLabelProps?.className)}
      control={<Checkbox {...props} size="large" className={classNames('rs-checkbox__box', props.className)} />}
      label={label}
    />
  );
};
