import { GridColDef } from '@mui/x-data-grid';

import { CompanyDeviceGroupRow } from './generate-customer-device-group-rows';
import { appConfig } from '../../../../configs';
import i18n from '../../../../i18n';
import { CompanyType } from '../../../../types';
import { DataGridLink } from '../../../5-elements';

export const companyDeviceGroupsDataGridColumns = (companyType: CompanyType): GridColDef<CompanyDeviceGroupRow>[] => {
  return [
    {
      field: 'name',
      hideable: false,
      sortable: true,
      flex: 2.4,
      minWidth: 240,
      headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
      cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
      headerName: i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.name'),
      renderCell: ({ id, value }) => {
        return <DataGridLink to={`${appConfig.basePath}/admin/device-groups/${id}`}>{value}</DataGridLink>;
      }
    },
    {
      field: companyType === CompanyType.Customer ? 'serviceProvider' : 'customer',
      hideable: false,
      sortable: true,
      flex: 2.4,
      minWidth: 240,
      headerName:
        companyType === CompanyType.Customer
          ? i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.serviceProvider')
          : i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.customer')
    },
    {
      field: 'deviceCount',
      hideable: false,
      sortable: true,
      flex: 1.7,
      minWidth: 170,
      headerName: i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.deviceCount')
    },
    {
      field: 'userCount',
      hideable: false,
      sortable: true,
      flex: 1.7,
      minWidth: 170,
      headerName: i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.userCount')
    },
    {
      field: 'createdAt',
      hideable: false,
      sortable: true,
      flex: 1.8,
      minWidth: 180,
      headerName: i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.createdAt')
    },
    {
      field: 'updatedAt',
      hideable: false,
      sortable: true,
      flex: 1.8,
      minWidth: 180,
      headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
      cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
      headerName: i18n.t('companyAdminDetailsPage.deviceGroupsDataGrid.updatedAt')
    }
  ];
};
